import { computed, effect, EventEmitter, inject, Injectable, NgZone, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { EEventKiwidSocket } from 'app/core/enums/socket.enum';
import { EventDetectorService } from 'app/core/helpers/event-detector.helper';
import { IAuthentication } from 'app/core/models/authentication/authentication';
import { IErrorWebsocket } from 'app/core/models/error/error';
import { EAccessType } from 'app/core/permissions/enum/access.enum';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable({
    providedIn: 'root',
})
export class KiwidSocketService {
    private readonly ngZone = inject(NgZone);
    private readonly translateService = inject(TranslateService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly eventDetectorService = inject(EventDetectorService);

    private socket: Socket;
    private reconnectAttempts = 0;
    private readonly maxReconnectAttempts = 5;
    private readonly reconnectInterval = 5000;
    private readonly disableConnectionInterval = 30000;
    private readonly isConnected$ = new BehaviorSubject<boolean>(false);
    private readonly eventOnline = toSignal(this.eventDetectorService.online());
    private readonly eventOffline = toSignal(this.eventDetectorService.offline());
    private readonly eventVisibilitychange = toSignal(this.eventDetectorService.visibilitychange());
    private readonly isLoadingConnection = signal(true);
    private visibilityTimeout: number | null = null;

    public readonly isConnected = toSignal(this.isConnected$.asObservable());
    public readonly isDisconnect = computed(() => !this.isConnected() && !this.isReconnecting());
    public readonly isReconnecting = signal(false);
    public readonly isConnecting = computed(() => this.isLoadingConnection());
    public readonly isOffline = signal(!navigator.onLine);
    public readonly onException = new EventEmitter<IErrorWebsocket>();
    public readonly connectionEvent = new EventEmitter<void>();

    constructor() {
        effect(() => {
            this.eventOffline();
            this.eventOnline();
            untracked(() => {
                this.isOffline.set(!navigator.onLine);
            });
        });

        let prevIsOffline: boolean | null = null;
        effect(() => {
            const isOffline = this.isOffline();

            untracked(() => {
                if (isOffline !== prevIsOffline) {
                    prevIsOffline = isOffline;

                    if (isOffline) {
                        this.disconnect();
                    } else if (!this.isLoadingConnection() && !this.isConnected()) {
                        this.attemptReconnect();
                    }
                }
            });
        });

        effect(() => {
            this.eventVisibilitychange();

            untracked(() => {
                if (document.hidden) {
                    this.handleTabHidden();
                } else {
                    this.handleTabVisible();
                }
            });
        });

        this.authenticationService.eventRefreshToken.subscribe(() => {
            if (this.socket?.connected) {
                this.emit(EEventKiwidSocket.AUTH_UPDATE_TOKEN, this.auth);
            }
        });
    }

    private get auth() {
        return {
            token: this.authenticationService.token,
            refreshToken: this.authenticationService.refreshToken,
            accessType: EAccessType.ADMIN_CLINIC,
            clinicId: this.authenticationService.clinicId(),
            lang: this.translateService.currentLang === 'pt' ? 'pt-br' : this.translateService.currentLang,
        };
    }

    public initSocket() {
        this.socket = io(`${environment.apiUrl}`, {
            autoConnect: false,
            reconnectionAttempts: 0,
            reconnection: false,
            transports: ['websocket'],
            auth: this.auth,
        });

        this.setupSocketListeners();
        this.connect();
    }

    public destroySocket() {
        this.reconnectAttempts = Number(this.maxReconnectAttempts);

        if (!!this.socket) {
            Object.values(EEventKiwidSocket).forEach((key) => {
                this.socket.off(key);
            });

            if (this.socket.connected) {
                this.socket.disconnect();
            }
        }

        this.socket = null;
    }

    private setupSocketListeners() {
        this.socket.on(EEventKiwidSocket.CONNECT, () => {
            this.isConnected$.next(true);
            this.reconnectAttempts = 0;
            this.connectionEvent.emit();
            this.isReconnecting.set(false);
            this.isLoadingConnection.set(false);
        });

        this.socket.on(EEventKiwidSocket.DISCONNECT, (error) => {
            this.isConnected$.next(false);
            this.attemptReconnect();
            this.isLoadingConnection.set(false);
        });

        this.socket.on(EEventKiwidSocket.CONNECT_ERROR, (error) => {
            if (!!error && String(error).search(environment.socketUnauthorized) > -1) {
                refreshToken();
                return;
            }

            this.socket.disconnect();
            this.isConnected$.next(false);
            this.attemptReconnect();
            this.isLoadingConnection.set(false);
        });

        this.socket.on(EEventKiwidSocket.ERROR, (error) => {
            console.error('ERROR', { error });
        });

        const refreshToken = () => {
            this.authenticationService
                .getRefreshToken({
                    refreshToken: this.authenticationService.refreshToken,
                })
                .pipe(first())
                .subscribe({
                    next: (result) => {
                        this.authenticationService.userCookie(result);
                        this.isLoadingConnection.set(false);
                        this.destroySocket();
                        this.initSocket();
                    },
                    error: (error) => {
                        this.authenticationService.logout();
                        this.isReconnecting.set(false);
                        this.isLoadingConnection.set(false);
                        this.disconnect();
                    },
                });
        };

        this.socket.on(EEventKiwidSocket.REFRESH_TOKEN, (data: IAuthentication) => {
            this.authenticationService.userCookie(data);
        });

        this.socket.on(EEventKiwidSocket.EXCEPTION, (error: IErrorWebsocket) => {
            if (error.message.search(environment.socketUnauthorized) > -1) {
                refreshToken();
                return;
            }
            this.onException.emit(error);
        });
    }

    public connect() {
        if (!!this.socket) {
            this.isLoadingConnection.set(true);
            this.socket.connect();
        }
    }

    public disconnect() {
        this.socket.disconnect();
    }

    public getConnectionStatus(): Observable<boolean> {
        return this.isConnected$.asObservable();
    }

    public on(event: EEventKiwidSocket, callback: (...args: any[]) => void): void {
        if (this.socket) {
            this.socket.on(event, callback);
        } else {
            console.error('Socket is not connected');
        }
    }

    public once(event: EEventKiwidSocket, callback: (...args: any[]) => void): void {
        if (this.socket) {
            this.socket.once(event, callback);
        } else {
            console.error('Socket is not connected');
        }
    }

    public emit(event: EEventKiwidSocket, ...args: any[]): void {
        if (this.socket) {
            this.socket.emit(event, ...args);
        } else {
            console.error('Socket is not connected');
        }
    }

    public fromEvent<T>(event: EEventKiwidSocket): Observable<T> {
        return new Observable((observer) => {
            const socket = this.socket;

            if (!!socket) {
                socket.on(event, (data: T) => {
                    observer.next(data);
                });
            }

            return () => {
                if (socket?.connected) {
                    socket.off(event);
                }
            };
        });
    }

    private attemptReconnect() {
        if (!navigator.onLine) {
            this.disconnect();
            this.isReconnecting.set(false);
            this.isConnected$.next(false);
            return;
        }

        this.isReconnecting.set(true);

        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                console.info(`Tentativa de reconexão ${this.reconnectAttempts + 1}`);
                this.reconnectAttempts++;
                this.connect();
            }, this.reconnectInterval);
        } else {
            this.isReconnecting.set(false);
            this.isConnected$.next(false);
        }
    }

    private handleTabHidden() {
        if (this.visibilityTimeout) {
            clearTimeout(this.visibilityTimeout);
        }

        this.visibilityTimeout = window.setTimeout(() => {
            this.ngZone.run(() => {
                this.reconnectAttempts = Number(this.maxReconnectAttempts);
                this.disconnect();
            });
        }, this.disableConnectionInterval);
    }

    private handleTabVisible() {
        if (this.visibilityTimeout) {
            clearTimeout(this.visibilityTimeout);
            this.visibilityTimeout = null;
        }

        if (!this.socket?.connected) {
            this.ngZone.run(() => {
                this.isReconnecting.set(true);
                this.connect();
            });
        }
    }
}
